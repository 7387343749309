import CloseIcon from 'shared/icons/CloseIcon'
import DownloadAndroidIcon from 'shared/icons/DownloadAndroidIcon'
import DownloadAppstoreIcon from 'shared/icons/DownloadAppstoreIcon'
import ModalWrapper from 'shared/components/modal/ModalWrapper'
import { useTrackingDownloadIOSClicked } from 'hooks/eventTracking'
import { useDeeplinkToPage } from 'hooks/useShowingAppSuggestion'
import { QRCodeSVG } from 'qrcode.react'

const DownloadAppModal = ({ onClose }: { onClose: () => void }) => {
  const deeplink = useDeeplinkToPage()
  const androidDeeplink = useDeeplinkToPage({ forIos: false })
  const trackClickingDownloadIos = useTrackingDownloadIOSClicked('footer_download_button')

  return (
    <ModalWrapper open onClose={onClose}>
      <div className="relative h-[370px] w-[325px] rounded-[32px] bg-white p-8 lg:h-[592px] lg:w-[444px] lg:p-10">
        <button className="absolute right-5 top-5" onClick={onClose}>
          <CloseIcon className="!h-8 !w-8" />
        </button>

        <div className="flex h-full w-full flex-col justify-between pt-5 lg:pt-0">
          <div className="space-y-3">
            <div className="text-center font-monument-grotes text-[39px] font-medium leading-[39px]">
              <h1>Download the</h1>
              <h1>mobile app</h1>
            </div>

            <div className="text-center">
              <p>Access the full experience</p>
              <p>in the mobile app</p>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center gap-8 rounded-[26px] border border-black/10 bg-[#F9FAFC] p-5 font-monument-grotes text-[39px] leading-[39px] lg:rounded-[40px] lg:p-10">
            <QRCodeSVG
              value={deeplink}
              size={200}
              level="L"
              imageSettings={{ src: '/images/logo.webp', height: 26, width: 50, excavate: true }}
              className="hidden lg:block"
            />

            <div className="flex flex-col items-center gap-2 lg:flex-row">
              <a href={deeplink} target="_blank" rel="noreferrer" onClick={trackClickingDownloadIos} className="inline">
                <DownloadAppstoreIcon className="!h-9 !w-[107px]" />
              </a>
              <a href={androidDeeplink} target="_blank" rel="noreferrer">
                <DownloadAndroidIcon className="!h-9 !w-[120px]" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default DownloadAppModal
