import cn from 'classnames'

type Props = {
  children?: React.ReactNode
  className?: string
}

const Chip = ({ children, className }: Props) => {
  return (
    <div
      className={cn(
        'mr-2 inline-block rounded-full border border-kokushoku-black bg-white p-2 px-3 text-[10px] uppercase text-kokushoku-black',
        className,
      )}
      data-testid="chip-component"
    >
      {children}
    </div>
  )
}

export default Chip
