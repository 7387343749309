const TwitterAltIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <span>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_6566_547576)">
          <path
            d="M7.86518 21.9396C16.9515 21.9396 21.9213 14.4117 21.9213 7.88349C21.9213 7.66967 21.9213 7.45682 21.9068 7.24493C22.8736 6.5456 23.7082 5.6797 24.3715 4.68778C23.4699 5.08749 22.5135 5.3495 21.5341 5.46504C22.5654 4.84772 23.3373 3.87664 23.706 2.73261C22.7363 3.30809 21.6753 3.71363 20.569 3.93172C19.8242 3.13971 18.8391 2.61527 17.7661 2.43954C16.6932 2.26381 15.5923 2.44659 14.6337 2.9596C13.6751 3.47261 12.9123 4.28725 12.4634 5.27746C12.0145 6.26766 11.9044 7.37822 12.1502 8.43729C10.1861 8.33877 8.26472 7.82834 6.5107 6.93913C4.75668 6.04992 3.20924 4.80182 1.96883 3.27582C1.3371 4.36336 1.14362 5.65079 1.42777 6.87598C1.71192 8.10117 2.45235 9.172 3.4983 9.87045C2.71209 9.84741 1.943 9.63531 1.25611 9.25211V9.31471C1.25642 10.4553 1.65125 11.5606 2.37363 12.4433C3.096 13.3259 4.10145 13.9315 5.21944 14.1574C4.49214 14.3558 3.72905 14.3848 2.9888 14.2421C3.30461 15.2237 3.91922 16.082 4.74673 16.6971C5.57424 17.3122 6.57328 17.6533 7.60417 17.6729C6.57991 18.478 5.40696 19.0732 4.15243 19.4246C2.8979 19.7759 1.5864 19.8765 0.292969 19.7205C2.55217 21.1702 5.18083 21.9392 7.86518 21.9357"
            fill="#191414"
          />
        </g>
        <defs>
          <clipPath id="clip0_6566_547576">
            <rect width="24.0785" height="24.0785" fill="white" transform="translate(0.292969 0.117798)" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export default TwitterAltIcon
