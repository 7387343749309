import cn from 'classnames'

type Props = {
  variant?: 'text' | 'circular' | 'rectangular'
  className?: string
}

const Skeleton = ({ className, variant = 'text' }: Props) => {
  if (variant === 'text') {
    return <div className={cn('h-[1.25em] animate-pulse bg-slate-300', className)} />
  }

  if (variant === 'circular') {
    return <div className={cn('animate-pulse rounded-full bg-slate-300', className)} />
  }

  return <div className={cn('animate-pulse bg-slate-300', className)} />
}

export default Skeleton
