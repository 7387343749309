import Chip from 'shared/components/Chip'
import RoundedImage from 'shared/components/RoundedImage'
import SocialButtons from 'shared/components/button/SocialButtons'
import Link from 'next/link'
import { TagBase } from 'types/V3/Tag'
import { UserProfile } from 'types/V3/User'
import { formatDate } from 'utils/datetime'
import ProfileSectionWrapper from '../ProfileSectionWrapper'

type AboutMeModalProps = {
  userProfile: UserProfile
  isCurrentUser: boolean
}

const AboutSection = (props: AboutMeModalProps) => {
  const { userProfile, isCurrentUser } = props
  const shouldShowAboutWorks = userProfile.titleStamp === 2 || userProfile.isVerified

  return (
    <div className="w-full space-y-2">
      <ProfileSectionWrapper
        title="About Me"
        editLink={isCurrentUser ? '/u/edit' : undefined}
        editText={isCurrentUser ? 'Edit >' : undefined}
      >
        <div className="flex flex-col gap-8 lg:flex-row">
          {/* main about */}
          <div className="flex-1 space-y-6 empty:hidden lg:space-y-8">
            {/* bio */}
            <div className="w-full space-y-4">
              {/* bio */}
              {userProfile.bio && (
                <>
                  <h4 className="text-[12px] font-semibold uppercase leading-[1.4] lg:text-[18px]">Bio</h4>
                  <p className="whitespace-pre-wrap">{userProfile.bio}</p>
                </>
              )}
              <div className="w-full space-y-2 empty:hidden">
                {/* social icons */}
                <SocialButtons
                  twitter={userProfile.twitter}
                  instagram={userProfile.instagram}
                  website={userProfile.website}
                />

                {/* roles */}
                <TagGroup tags={userProfile.tags} />
              </div>
            </div>

            {/* about works */}
            {shouldShowAboutWorks && (
              <TagGroup
                tags={[...userProfile.workMoods, ...userProfile.workMediums, ...userProfile.workValues]}
                label="About My Work"
              />
            )}

            {/* interested in */}
            <TagGroup
              tags={[...userProfile.moods, ...userProfile.mediums, ...userProfile.values]}
              label="Interested In"
            />
          </div>

          {/* more about */}
          <div className="flex-1 space-y-6 lg:space-y-8">
            <MoreAboutInfo title="Affiliations" content={userProfile.affiliationsArray} />
            <MoreAboutInfo title="You might have seen me at" content={userProfile.seenMeAt} />
            <MoreAboutInfo title="Currently" content={userProfile.currently} />
            {/* invited by */}
            <div className="mt-8 space-y-4">
              <h4 className="text-[12px] font-semibold uppercase leading-[1.4] empty:hidden lg:text-[18px]">
                Invited by
              </h4>
              <div>
                <Link
                  href={`/u/${userProfile.referralBy?.username}`}
                  passHref
                  className="flex w-fit flex-row items-center space-x-4 pt-1"
                >
                  <div className="relative h-8 w-8">
                    <RoundedImage src={userProfile.referralBy?.coverImage} bordered alt="referral user" />
                  </div>
                  <div>
                    <div className="text-sm leading-5">{userProfile.referralBy?.fullName}</div>
                    <div className="text-[10px] leading-3 text-[#666666]">
                      {userProfile.createdAt &&
                        formatDate(
                          new Date(userProfile.createdAt), // unix time in seconds
                          'MMM DD, YYYY',
                        )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </ProfileSectionWrapper>
    </div>
  )
}

type MoreAboutInfoProps = {
  title: string
  content: string[] | string | null
}

const MoreAboutInfo = ({ title, content }: MoreAboutInfoProps) => {
  if (!content?.length) return <></>
  const itemsArr = typeof content === 'string' ? [content] : content
  return (
    <div className="space-y-4 border-b border-black/10 pb-4">
      <h4 className="text-[12px] font-semibold uppercase leading-[1.4] empty:hidden lg:text-[18px]">{title}</h4>
      <div className="w-full max-w-[380px] text-[12px] capitalize leading-[1.4] lg:text-[14px]">
        {itemsArr.map((item, index) => (
          <div
            className="mr-1 mt-1 inline-block before:content-['_•_'] first:pl-0 first:before:content-['_'] "
            key={index}
          >
            <span>{item}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

type TagGroupProps = {
  tags: Array<TagBase>
  label?: string
}

const TagGroup = (props: TagGroupProps) => {
  const { tags, label } = props
  if (!tags.length) return null

  return (
    <div className="space-y-4">
      <h4 className="text-[12px] font-semibold uppercase leading-[1.4] empty:hidden lg:text-[18px]">{label}</h4>
      <div className="space-y-2">
        {tags.map(({ title }, index) => {
          return <Chip key={index}>{title}</Chip>
        })}
      </div>
    </div>
  )
}

export default AboutSection
