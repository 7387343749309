import classNames from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'

type WelcomeScreenProps = {
  onCloseClick: () => void
  onCohartHelpClick: () => void
  onMaybeLaterClick: () => void
}
const WelcomeScreen = (props: WelcomeScreenProps) => {
  const { onCloseClick, onCohartHelpClick, onMaybeLaterClick } = props
  return (
    <>
      {/* close button */}
      <div className="flex justify-end">
        <button type="button" onClick={onCloseClick}>
          <XMarkIcon className="h-6 w-6 text-black" />
        </button>
      </div>

      {/* title */}
      <div className="h-2 lg:h-5" />
      <h4 className="text-center text-[22px] font-semibold leading-[120%] lg:text-[28px]">
        Welcome to Cohart! Let’s add more of your collection!
      </h4>

      {/* buttons */}
      <div className="h-5" />
      <div className="flex flex-col items-center gap-2 lg:flex-row lg:justify-center">
        <Link
          passHref
          href="/artworks/upload"
          className={classNames(
            'w-[170px] rounded-[30px] bg-primary py-4 text-xs font-medium text-white',
            'flex justify-center transition-shadow hover:shadow-lg',
          )}
          // also close the modal, so it wont show again
          onClick={onCloseClick}
        >
          Upload artwork
        </Link>
        <button
          className={classNames(
            'w-[170px] rounded-[30px] border border-black/10 py-4 text-xs font-medium shadow-sm',
            'transition-shadow hover:shadow-lg',
          )}
          onClick={onCohartHelpClick}
        >
          Let Cohart help me
        </button>
      </div>

      {/* maybe later */}
      <div className="h-2 lg:h-5" />
      <div className="flex justify-center">
        <button className="text-sm font-medium" onClick={onMaybeLaterClick}>
          Maybe later
        </button>
      </div>

      {/* artworks */}
      <div className="h-8 lg:h-12" />
      <div className="relative flex">
        <div className="absolute left-[45%] top-5 rotate-[6deg] lg:left-[300px] ">
          <ArtworkItem
            isSold={true}
            thumbnailClassName="h-[186px] w-full rounded-t-[10px] bg-gradient-to-tr from-[#61FF00] to-[#FFD600] "
          />
        </div>
        <div className="absolute left-[25%] top-0 lg:left-40">
          <ArtworkItem thumbnailClassName="h-[193px] w-full rounded-t-[10px] bg-gradient-to-tr from-[#AD00FF] to-[#FF6B00] " />
        </div>
        <div className="absolute left-[5%] top-[60px] -rotate-[6deg] lg:left-10 ">
          <ArtworkItem thumbnailClassName="h-[100px] w-full rounded-t-[10px] bg-gradient-to-tr from-[#61FF00] to-[#0066FF] " />
        </div>
      </div>
    </>
  )
}

export default WelcomeScreen

type ArtworkItemProps = {
  thumbnailClassName: string
  isSold?: boolean
}

const ArtworkItem = ({ thumbnailClassName, isSold }: ArtworkItemProps) => {
  return (
    <div className="w-[153px] space-y-1 rounded-[10px] border border-black/10 bg-white shadow-sm">
      <div className={thumbnailClassName} />
      <div className="flex justify-between px-2">
        <span className="flex items-center gap-2 text-[10px] font-medium text-black">
          <span className="inline-block h-3 w-3 rounded-full bg-[#E3E3E3]" />
          User Name
        </span>

        {isSold && (
          <span
            className={classNames(
              'inline-flex items-center rounded-full bg-white text-[8px] font-medium uppercase',
              'h-[23px] gap-[4px] pl-[6px] pr-[8px] text-[10px] font-semibold',
              'rounded-[8px] border border-black/10',
            )}
          >
            <i className="h-[8px] w-[8px] rounded-full bg-[#FF453A]" />
            Sold
          </span>
        )}
      </div>
      <p className="px-2 pb-2 text-xs font-medium text-black">Artwork Title</p>
    </div>
  )
}
