import { Fragment, ReactNode, useCallback, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import cn from 'classnames'

export type ResponsiveModalWrapperProps = {
  children: ReactNode
  open: boolean
  blurBackdrop?: boolean
  onClose?: () => void
  className?: string
  disableBackdropClick?: boolean
  autoFocus?: boolean
}

const ResponsiveModalWrapper = (props: ResponsiveModalWrapperProps) => {
  const { open, onClose, blurBackdrop, disableBackdropClick, className, autoFocus } = props
  const onBackdropClick = useCallback(() => {
    if (disableBackdropClick) return
    if (!onClose) return
    return onClose()
  }, [onClose, disableBackdropClick])

  const hiddenFocusRef = useRef(null)
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className={cn('relative z-[60]', className)}
          onClose={onBackdropClick}
          initialFocus={autoFocus ? undefined : hiddenFocusRef}
        >
          <div className={cn('fixed inset-0 bg-black/60', blurBackdrop && 'backdrop-blur-md')} />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full flex-col items-center justify-end lg:justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel as="div" className="relative flex h-full items-center justify-center">
                  {props.children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ResponsiveModalWrapper
