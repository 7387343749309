import cn from 'classnames'
import { useDeeplinkToPage } from 'hooks/useShowingAppSuggestion'
import { useTrackingDownloadIOSClicked } from 'hooks/eventTracking'
import DownloadAppstoreIcon from 'shared/icons/DownloadAppstoreIcon'
import DownloadAndroidIcon from 'shared/icons/DownloadAndroidIcon'

type DownloadModalProps = {
  show?: boolean
  onClose?: () => unknown
}

const DownloadModal = (props: DownloadModalProps) => {
  const { show } = props
  const deeplink = useDeeplinkToPage()
  const androidDeeplink = useDeeplinkToPage({ forIos: false })
  const trackClickingDownloadIos = useTrackingDownloadIOSClicked('profile_download_banner')

  return (
    <div
      className={cn(
        'fixed bottom-14 left-1/2 z-10 grid -translate-x-1/2 place-items-center rounded-lg bg-white p-2 opacity-0 shadow-cohart-xl',
        {
          invisible: !show,
          'animate-fadein delay-500 duration-500 ': show,
        },
      )}
      style={{ boxShadow: '0px 0px 15px rgba(0, 0, 255, 0.4), 0px 0px 40px 40px rgba(255, 255, 255, 0.2)' }}
      data-testid="download-modal-component"
    >
      <div className="flex h-full w-full flex-col items-center justify-between gap-2 lg:flex-row">
        <span className="text-sm 3xl:text-2xl">Download Cohart for iOS and Android.</span>

        <div className="flex items-center gap-1">
          <a
            href={deeplink}
            target="_blank"
            className="relative h-full w-auto cursor-pointer"
            rel="noreferrer"
            onClick={trackClickingDownloadIos}
          >
            <DownloadAppstoreIcon className="!h-[45px] !w-[135px] lg:!h-[30px] lg:!w-[90px] 3xl:!h-[60px] 3xl:!w-[180px]" />
          </a>
          <a href={androidDeeplink} target="_blank" rel="noreferrer">
            <DownloadAndroidIcon className="!h-[45px] !w-[151px] lg:!h-[30px] lg:!w-[102px] 3xl:!h-[60px] 3xl:!w-[204px]" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default DownloadModal
