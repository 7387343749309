import { WEB_BASE_URL } from 'configs'
import { UserBase, UserProfile, UserVerification } from 'types/V3/User'

export const generateArtistJsonLd = (
  userProfile: UserProfile & { collectors: Array<UserBase & UserVerification> },
): { __html: string } => {
  const baseUrl = `https://${WEB_BASE_URL}`
  return {
    __html: `{
      "@context": "http://schema.org",
      "@type": "Person",
      "name": "${userProfile.fullName?.replace(/"/g, "'")}",
      "sameAs": "${baseUrl}/u/${userProfile.username}",
      "additionalType": "Artist",
      "url": "${baseUrl}/u/${userProfile.username}",
      "image": "${userProfile.coverImage || ''}",
      "description": "${userProfile.bio?.replace(/"/g, "'") || ''}"
    }`,
  }
}
