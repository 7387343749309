import React, { memo, useCallback, useEffect, useState } from 'react'
import ResponsiveModalWrapper from 'shared/components/modal/ResponsiveModalWrapper'
import classNames from 'classnames'
import WelcomeScreen from './WelcomeUserScreen'
import CohartHelpScreen from './CohartHelpScreen'
import { useLocalStorage } from 'usehooks-ts'
import useShowingInfoOnce from 'hooks/useShowingInfoOnce'
import requestBulkUpload from 'shared/services/requestBulkUpload'
import { notifyError } from 'utils/toast'
import useAuth from 'hooks/authentication/useAuth'

type WelcomeModalProps = {
  onCloseClick: () => void
}

const WelcomeModal = (props: WelcomeModalProps) => {
  const { authUser } = useAuth()
  const { onCloseClick } = props
  const [screen, setScreen] = useState<'welcome-user' | 'cohart-help'>('welcome-user')
  const [requestHelpSent, setRequestHelpSent] = useState(false)

  // Handlers
  const handleCohartHelp = useCallback(() => {
    setScreen('cohart-help')
  }, [setScreen])

  const onBackClick = useCallback(() => {
    setScreen('welcome-user')
  }, [setScreen])

  const onRequestHelp = useCallback(async () => {
    if (!authUser) return
    try {
      await requestBulkUpload({
        email: authUser.email,
        numberOfArtwork: 1,
      })
      setRequestHelpSent(true)
    } catch (error) {
      const message = typeof error === 'string' ? error : 'Something went wrong'
      notifyError(message)
    }
  }, [authUser])

  const [isVisible, setIsVisible] = useState(false)

  // show modal after 5s
  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 5000)
    return () => clearTimeout(timeout)
  }, [])

  if (!isVisible) return null
  return (
    <ResponsiveModalWrapper open onClose={onCloseClick}>
      <div
        className={classNames(
          'relative w-[95vw] overflow-hidden bg-white p-6 font-inter',
          'rounded-[24px] lg:w-[600px] lg:p-8',
          screen === 'welcome-user' && 'my-[5vh] h-[90vh] lg:my-0 lg:h-[617px]',
          screen === 'cohart-help' && 'my-[25vh] h-[50vh] lg:my-0 lg:h-[300px]',
        )}
      >
        {screen === 'welcome-user' && (
          <WelcomeScreen
            onCloseClick={onCloseClick}
            onCohartHelpClick={handleCohartHelp}
            onMaybeLaterClick={onCloseClick}
          />
        )}
        {screen === 'cohart-help' && (
          <CohartHelpScreen
            onCloseClick={onCloseClick}
            onBackClick={onBackClick}
            onRequestHelp={onRequestHelp}
            requestHelpSent={requestHelpSent}
          />
        )}
      </div>
    </ResponsiveModalWrapper>
  )
}

export default memo(WelcomeModal)

export const useShowingWelcomeModal = () => {
  const [shouldWelcome] = useLocalStorage('shouldWelcome', false)
  const [isNewUserCongratsModalShown, setIsNewUserCongratsModalShown] = useShowingInfoOnce('newUserCongratsModal')
  const isWelcomeModalVisible = shouldWelcome && !isNewUserCongratsModalShown
  const onCloseWelcomeModal = useCallback(() => setIsNewUserCongratsModalShown(true), [setIsNewUserCongratsModalShown])

  return {
    isWelcomeModalVisible,
    onCloseWelcomeModal,
  }
}
