import { ArrowLeftIcon, CheckIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useState } from 'react'

type CohartHelpScreenProps = {
  onCloseClick: () => void
  onBackClick: () => void
  onRequestHelp: () => void
  requestHelpSent: boolean
}
const CohartHelpScreen = (props: CohartHelpScreenProps) => {
  const { onCloseClick, onBackClick, onRequestHelp, requestHelpSent } = props
  const [isRequesting, setIsRequesting] = useState(false)

  const onRequestUploadArtworks = async () => {
    try {
      setIsRequesting(true)
      await onRequestHelp()
    } finally {
      setIsRequesting(false)
    }
  }

  return (
    <>
      {/* close button */}
      <div className="flex justify-between">
        <button className="flex items-center gap-2 text-sm" onClick={onBackClick}>
          <ArrowLeftIcon className="h-4 w-4 text-black" />
          Back
        </button>
        <button type="button" onClick={onCloseClick}>
          <XMarkIcon className="h-6 w-6 text-black" />
        </button>
      </div>

      {/* title */}
      <div className="h-5" />
      <h4 className="text-center text-[22px] font-semibold leading-[120%] lg:text-[28px]">
        Our team can help upload your art, so you don't have to!
      </h4>

      {/* buttons */}
      <div className="h-16" />
      <div className="flex justify-center gap-2">
        {!requestHelpSent && (
          <button
            className={classNames(
              'flex items-center gap-2 px-8 py-4 text-xs font-medium text-white',
              'rounded-[30px] bg-primary transition-shadow hover:shadow-lg',
              'disabled:cursor-progress disabled:opacity-40',
            )}
            disabled={isRequesting}
            onClick={onRequestUploadArtworks}
          >
            <span className="rounded-full bg-white p-1">
              <PlusIcon className="h-4 w-4 text-primary" />
            </span>
            <span>Help me upload my artworks</span>
          </button>
        )}
        {requestHelpSent && (
          <span className="flex items-center gap-2 text-xs font-medium text-primary">
            <CheckIcon className="h-4 w-4 text-primary" />
            <span>Support requested!</span>
          </span>
        )}
      </div>
    </>
  )
}

export default CohartHelpScreen
