import { memo, useMemo } from 'react'
import { getValidHrefFromLink } from 'utils/common'
import TwitterAltIcon from '../../icons/TwitterAltIcon'
import InstagramAltIcon from '../../icons/InstagramAltIcon'
import WebsiteIcon from '../../icons/WebsiteIcon'
import classNames from 'classnames'

type SocialButtonsProps = {
  instagram?: string | null
  twitter?: string | null
  website?: string | null
  className?: string
}

const SocialButtons = (props: SocialButtonsProps) => {
  const { twitter, instagram, website, className } = props

  const twitterLink = useMemo(() => {
    const twitterURL = `https://twitter.com/${twitter}`
    return (
      <div className="cursor-pointer">
        <a
          href={twitterURL}
          aria-label="user twitter"
          target="_blank"
          rel="noreferrer"
          data-testid="social-icon-twitter-anchor"
        >
          <TwitterAltIcon />
        </a>
      </div>
    )
  }, [twitter])

  const instagramLink = useMemo(() => {
    const instagramURL = `https://instagram.com/${instagram}`
    return (
      <div className="cursor-pointer">
        <a
          href={instagramURL}
          aria-label="user instagram"
          target="_blank"
          rel="noreferrer"
          data-testid="social-icon-instagram-anchor"
        >
          <InstagramAltIcon />
        </a>
      </div>
    )
  }, [instagram])

  const websiteLink = useMemo(() => {
    if (!website) return null
    const validWebsite = getValidHrefFromLink(website)
    if (!validWebsite) return null
    return (
      <a
        href={validWebsite}
        aria-label="user instagram"
        target="_blank"
        rel="noreferrer"
        data-testid="social-icon-website-anchor"
        className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
      >
        <WebsiteIcon />
      </a>
    )
  }, [website])

  if (!instagram && !twitter && !website) return null

  return (
    <div
      className={classNames('inline-flex items-center space-x-6 text-3xl', className)}
      data-testid="social-buttons-component"
    >
      {websiteLink}
      {instagram && instagramLink}
      {twitter && twitterLink}
    </div>
  )
}

export default memo(SocialButtons)
