import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'
import { withLogger } from 'utils/Logger'

const requestBulkUpload = async (data: { email: string; numberOfArtwork: number }) => {
  const response = await withApiErrorHandled(apiClient.post)('/store/email/bulk-upload-new', data)
  const { message } = response.data as NewBackEndApiResponseData
  return message
}

export default withLogger(requestBulkUpload, {
  action: 'request_bulk_upload',
  customErrorText: 'Cannot request bulk upload',
})
