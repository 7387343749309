import Link from 'next/link'
import { memo, useCallback, useMemo } from 'react'
import ScrollWrapper from 'shared/components/layout/ScrollWrapper'
import classNames from 'classnames'
import flattenDeep from 'lodash.flattendeep'
import useFetchingMoments from 'shared/hooks/useFetchingMoments'
import { MomentBase } from 'types/Moment'
import MomentCard from 'shared/components/thumbnails/MomentCard'
import DownloadAppModal from 'shared/components/modal/DownloadAppModal'
import { useBoolean } from 'usehooks-ts'
import EmptyIcon from 'shared/icons/EmptyIcon'
import Skeleton from 'shared/components/loader/Skeleton'
import ProfileSectionWrapper from './ProfileSectionWrapper'
import checkIfValidMoment from '../utils/checkIfValidMoment'

const StyledSkeleton = () => (
  <Skeleton className="h-[250px] min-w-[167px] flex-1 lg:h-[320px] lg:min-w-[200px]" variant="rectangular" />
)

type ProfileMomentsCarouselProps = {
  username: string
  isLoggedIn: boolean
  isCurrentUser: boolean
}

const ProfileMomentsCarousel = (props: ProfileMomentsCarouselProps) => {
  const { username, isLoggedIn, isCurrentUser } = props
  const { value: isQRModalOpen, toggle: toggleQRModal } = useBoolean(false)
  const {
    data: momentData,
    isLoadingInitialData: isLoading,
    isEmpty: isMomentsDataEmpty,
  } = useFetchingMoments(username, isLoggedIn)

  const moments = useMemo(() => (momentData ? flattenDeep(momentData) : []), [momentData])

  const displayedMoments = useMemo(() => moments.filter((item) => checkIfValidMoment(item)), [moments])

  const renderMomentItem = useCallback(
    (moment: MomentBase) => (
      <div key={moment.id} className="w-[165px] empty:hidden lg:w-[200px]">
        <MomentCard moment={moment} toggleQRModal={toggleQRModal} hideAuthorInfo={true} />
      </div>
    ),
    [toggleQRModal],
  )

  const isMomentsEmpty = isMomentsDataEmpty || moments.length === 0

  const seeAllLink = isMomentsEmpty ? undefined : `/u/${username}/moments`

  if (!isCurrentUser && !isLoading && isMomentsEmpty) return null

  return (
    <>
      <ProfileSectionWrapper
        title="Moments"
        seeAllLink={seeAllLink}
        editLink={isCurrentUser ? '/u/videos/upload' : undefined}
        editText={isCurrentUser ? '+ Share' : undefined}
      >
        <div className="relative flex h-full min-h-[250px] w-full items-center lg:min-h-[320px]">
          {/* loading state */}
          {isLoading && (
            <div className="flex h-full w-full gap-2 lg:gap-4">
              <StyledSkeleton />
              <StyledSkeleton />
              <StyledSkeleton />
              <StyledSkeleton />
              <StyledSkeleton />
              <StyledSkeleton />
            </div>
          )}

          {/* Empty State for Current User */}
          {!isLoading && isMomentsEmpty && isCurrentUser && (
            <div className="flex h-full w-full flex-col items-center justify-center text-sm text-[#8B8B8B]">
              <Link passHref href="/u/videos/upload" legacyBehavior>
                <EmptyIcon className="cursor-pointer" />
              </Link>
              <span>You have not shared any moment yet.</span>
              <span>Start sharing one now!</span>
            </div>
          )}

          {!isLoading && !isMomentsEmpty && (
            <ScrollWrapper autoHide={false} wrapperClassName="full-width-scroll-wrapper w-full empty:hidden ">
              <div
                className={classNames(
                  'inline-flex items-stretch gap-2',
                  'px-1 pb-6 pt-1', // for scrollbar
                  'empty:hidden lg:gap-4',
                )}
              >
                {/* moments contents */}
                {displayedMoments.map(renderMomentItem)}
              </div>
            </ScrollWrapper>
          )}
        </div>
      </ProfileSectionWrapper>

      {/* Download Modal */}
      {isQRModalOpen && <DownloadAppModal onClose={toggleQRModal} />}
    </>
  )
}

export default memo(ProfileMomentsCarousel)
